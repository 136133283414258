import React, { useEffect } from 'react';
import { WithT } from 'i18next';
import cn from 'clsx';

import { Link } from '../../common/A11y/Link';
import { CurrentEventLayer } from '../../../domain/editorial';
import { AssetFile } from '../../../domain/media';
import { hasDefinedUrl } from '../../../domain/utils/AssetFileUtils';

import './HomeCurrentEvent.module.scss';

interface HomeCurrentEventProps extends WithT {
    currentEvent: CurrentEventLayer;
}

export const HomeCurrentEvent = ({ currentEvent, t }: HomeCurrentEventProps): JSX.Element => {
    const { title, currentEvents } = currentEvent;
    const { shortDescription, title: eventTitle, poster, color = '#0088c7' } = currentEvents[0];
    const foundPoster: AssetFile | undefined | null = poster
        ? poster.files.find((file): boolean => hasDefinedUrl(file))
        : null;

    useEffect((): void => {
        const CurrentEventRoot = document.querySelector<HTMLElement>('.CurrentEvent');

        if (CurrentEventRoot !== null) {
            CurrentEventRoot.style.setProperty('--bg', color);
        }
    }, [color]);

    return (
        <section className="CurrentEvent">
            <h2 className="CurrentEvent-Title">
                <span className="CurrentEvent-Title-FirstLetter">{title.charAt(0)}</span>
                <span className="CurrentEvent-Title-Rest">{title.slice(1)}</span>
                <span className="visually-hidden">{title}</span>
            </h2>
            <div className="CurrentEvent-Infos">
                <div className="CurrentEvent-Infos-Content">
                    <div className="CurrentEvent-Infos-Content-Box">
                        <h3 className="CurrentEvent-Infos-Content-Box-Title">{eventTitle}</h3>
                        <p className="CurrentEvent-Infos-Content-Box-Description">{shortDescription}</p>
                    </div>
                    <Link
                        href="/events"
                        className={cn(
                            'CurrentEvent-Infos-Content-Link',
                            'CurrentEvent-Infos-Content-Link-Desktop',
                            'is-stroke-effect button'
                        )}
                    >
                        <span>{t('current_event:seeAllEvents')}</span>
                    </Link>
                    <Link
                        href="/events"
                        className={cn(
                            'CurrentEvent-Infos-Content-Link',
                            'CurrentEvent-Infos-Content-Link-Mobile',
                            'link-dotted'
                        )}
                    >
                        <span>{t('current_event:seeAllEvents')}</span>
                    </Link>
                </div>
                {foundPoster && <img src={foundPoster.url} alt={title} className="CurrentEvent-Infos-Poster" />}
            </div>
        </section>
    );
};
