import React, { FunctionComponent } from 'react';
import { WithT } from 'i18next';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../../common/ModalDialog';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import { Review } from '../../../../domain/community';
import dayjs from 'dayjs';
import { ActionCreator } from 'redux';
import { UIAction } from '../../../../modules/UI';
import { FrontUser } from '../../../../domain/user';
import { ReviewUpdateAction } from '../../../../modules/reviews';

export interface ReadReviewModalProps extends WithT {
    isOpen?: boolean;
    onClose?: () => void;
    review: Review | null;
    toggleAddReviewModal: ActionCreator<UIAction>;
    toggleConfirmReviewDeletionModal: ActionCreator<UIAction>;
    editReview: ActionCreator<ReviewUpdateAction>;
    frontUser?: FrontUser;
}

const renderLoggedActionButtons = (props: ReadReviewModalProps): JSX.Element | null => {
    if (props.review == null || props.frontUser == null || props.review.frontUser.id !== props.frontUser.id) {
        return null;
    }
    return (
        <div className="ReadReviewModal-Header-Meta-Buttons">
            <button
                type="button"
                className="ReadReviewModal-Header-Meta-Edit link-dotted"
                title={props.t('common:edit')}
                onClick={(): void => {
                    props.editReview(props.review);
                    props.toggleAddReviewModal();
                }}
            >
                {props.t('common:edit')}
            </button>
            <button
                type="button"
                className="ReadReviewModal-Header-Meta-Delete link-dotted"
                title={props.t('common:delete')}
                onClick={(): void => {
                    props.editReview(props.review);
                    props.toggleConfirmReviewDeletionModal();
                }}
            >
                {props.t('common:delete')}
            </button>
        </div>
    );
};

export const ReadReviewModal: FunctionComponent<ReadReviewModalProps> = (
    props: ReadReviewModalProps
): JSX.Element | null => {
    const { isOpen, onClose, review, t, frontUser } = props;
    const reviewerIsLoggedUser = frontUser != null && review != null && review.frontUser.id === frontUser.id;
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    if (!review) {
        return null;
    }
    const reviewerName = `${review.frontUser.givenName} ${review.frontUser.lastName}`;
    return (
        <ModalDialog isOpen={!!isOpen} onEscKeyPress={handleClose} onOutsideClick={handleClose}>
            <ModalDialogHeader className="ReadReviewModal-Header" onClose={handleClose} t={t}>
                {t('common:review_of', { movie: review.movie.title })}
                <div className="ReadReviewModal-Header-Meta">
                    {!reviewerIsLoggedUser && (
                        <span className="ReadReviewModal-Header-Meta-Reviewer">{reviewerName}</span>
                    )}
                    <span className="ReadReviewModal-Header-Meta-Date">
                        {dayjs(review.createdAt).format('DD MMMM YYYY HH mm')}
                    </span>
                    {renderLoggedActionButtons(props)}
                </div>
            </ModalDialogHeader>
            <ModalDialogBody>
                <div className="ReadReviewModal-Title">{review.title}</div>
                <div className="ReadReviewModal-Review">{review.review}</div>
            </ModalDialogBody>
        </ModalDialog>
    );
};
