import React, { FunctionComponent } from 'react';
import { EventLayer } from '../../../domain/editorial';
import { WithTranslation } from 'react-i18next';
import { TFunction, i18n } from 'i18next';

export type SentenceAnimationProps = Pick<EventLayer, 'countryCount' | 'screeningCount' | 'movieCount'> &
    WithTranslation;

function renderCountSentence(
    { language: locale }: i18n,
    t: TFunction,
    i18nKey: string,
    count: number = 0
): JSX.Element {
    return <span>{t(i18nKey, { count, formattedCount: count.toLocaleString(locale) })}</span>;
}

export const SentenceAnimation: FunctionComponent<SentenceAnimationProps> = ({
    t,
    i18n,
    countryCount,
    screeningCount,
    movieCount,
}: SentenceAnimationProps): JSX.Element => {
    return (
        <div className="SentenceAnimation">
            {t('homepage:ifcinema')}
            <br />
            <div className="SentenceAnimation-SlidingVertical">
                {renderCountSentence(i18n, t, 'homepage:screeningsCount', screeningCount)}
                {renderCountSentence(i18n, t, 'homepage:moviesCount', movieCount)}
                {renderCountSentence(i18n, t, 'homepage:countriesCount', countryCount)}
            </div>
        </div>
    );
};
