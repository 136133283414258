import { Focus } from '../editorial';
import find from 'lodash/find';
import { hasDefinedUrl } from './AssetFileUtils';
import { AssetFile } from '../media';
import { getOptimizedImageUrl } from '../../utils/imageOptimizer';

export function getFocusMainImageUrl(focus?: Focus): string | undefined {
    if (focus == null || focus.mainImage == null) {
        return undefined;
    }
    const foundAssetFile: AssetFile | undefined = find(focus.mainImage.files, hasDefinedUrl);
    return foundAssetFile ? getOptimizedImageUrl(foundAssetFile.url, 1440, 810) : undefined;
}
