import React from 'react';
import Slider from 'react-slick';
import { MovieSlide } from '../../common/MovieSlide';
import { WithT } from 'i18next';
import { MovieListLayer } from '../../../domain/editorial';
import { MovieSlideData } from '../../../domain/work';
import classNames from 'clsx';

export interface LastChangeProps extends WithT {
    movieListLayer?: MovieListLayer;
}

const LastChance = ({ movieListLayer, t }: LastChangeProps): JSX.Element | null => {
    if (movieListLayer == null || movieListLayer.movies == null || movieListLayer.movies.length < 1) {
        return null;
    }

    const title = t('lastChance');
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToScroll: 3,
        initialSlide: 0,
        draggable: false,
        variableWidth: true,
    };

    return (
        <section className="LastChance">
            <h2 className={classNames('title', 'title-3')}>
                <span className="first-letter">{title.charAt(0)}</span>
                <span>{title.slice(1)}</span>
                <span className="visually-hidden">{title}</span>
            </h2>
            <Slider {...settings} className="slider-horizontal">
                {movieListLayer.movies.slice(0, 4).map(
                    (movie: MovieSlideData, i: number): JSX.Element => (
                        <MovieSlide key={i} movie={movie} parentId="LastChance" t={t} />
                    )
                )}
            </Slider>
        </section>
    );
};

export default LastChance;
