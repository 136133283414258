import React from 'react';
import { Link } from '../../common/A11y/Link';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { WithT } from 'i18next';
import { NewsLayer, NewsItem } from '../../../domain/editorial';
import classNames from 'clsx';

library.add(faArrowRight);

export interface NewsProps extends WithT {
    newsLayer?: NewsLayer;
}

const News = ({ t, newsLayer }: NewsProps): JSX.Element | null => {
    if (newsLayer == null || newsLayer.items == null || newsLayer.items.length < 1) {
        return null;
    }

    const title = t('news');
    return (
        <section className="Actu">
            <h2 className={classNames('title', 'title-3')}>
                <span className="first-letter">{title.charAt(0)}</span>
                <span>{title.slice(1)}</span>
                <span className="visually-hidden">{title}</span>
            </h2>
            <ul className="list-arrow-box">
                {newsLayer.items.map(
                    (newsItem: NewsItem, i: number): JSX.Element => (
                        <li key={i}>
                            {newsItem.externalUrl && (
                                <Link href={newsItem.externalUrl} className="arrow-box">
                                    <span>{newsItem.subtitle}</span>
                                    <p>{newsItem.title}</p>
                                    <FontAwesomeIcon aria-hidden="true" icon={faArrowRight} />
                                </Link>
                            )}
                        </li>
                    )
                )}
            </ul>
        </section>
    );
};

export default News;
