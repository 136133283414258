import React, { FunctionComponent } from 'react';
import { WithT } from 'i18next';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../../common/ModalDialog';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import { AppState } from '../../../../store/reducers';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { MovieStateData } from '../../../../modules/movie';
import { Button } from '../../../common/A11y/Button';
import { ActionButton } from '../../../common/Button';
import { Review } from '../../../../domain/community';
import { createDeleteReviewAction, ReviewUpdateAction } from '../../../../modules/reviews';

export interface OwnProps extends WithT {
    isOpen?: boolean;
    onClose?: () => void;
    onReviewDeleted?: () => void;
}

interface StateProps {
    movie?: MovieStateData;
    reviewToEdit?: Review;
}

interface DispatchProps {
    deleteReview: ActionCreator<ReviewUpdateAction>;
}

type ConfirmReviewDeletionModalProps = OwnProps & StateProps & DispatchProps;

const _ConfirmReviewDeletionModal: FunctionComponent<ConfirmReviewDeletionModalProps> = ({
    isOpen,
    onClose,
    movie,
    reviewToEdit,
    deleteReview,
    onReviewDeleted,
    t,
}: ConfirmReviewDeletionModalProps): JSX.Element | null => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;

    if (!movie) {
        return null;
    }

    return (
        <ModalDialog isOpen={!!isOpen} onEscKeyPress={handleClose} onOutsideClick={handleClose}>
            <ModalDialogHeader className="ConfirmReviewDeletionModal-Header" onClose={handleClose} t={t}>
                {t('common:delete_review_of', { movie: movie.title })}
            </ModalDialogHeader>
            <ModalDialogBody>
                {t('common:this_action_is_irreversible')}
                <div className="ConfirmReviewDeletionModal-Buttons">
                    <Button
                        className="ConfirmReviewDeletionModal-Buttons-Cancel link-dotted"
                        onClick={handleClose}
                        text={t('common:cancel')}
                    />
                    <ActionButton
                        text={t('common:do_delete')}
                        onClick={(): void => {
                            deleteReview(reviewToEdit);
                            if (onReviewDeleted) {
                                onReviewDeleted();
                            }
                        }}
                    />
                </div>
            </ModalDialogBody>
        </ModalDialog>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    movie: state.movie.data,
    reviewToEdit: state.reviews.reviewToEdit,
});
const mapDispatchToProps: DispatchProps = {
    deleteReview: createDeleteReviewAction,
};
export const ConfirmReviewDeletionModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(_ConfirmReviewDeletionModal) as FunctionComponent<OwnProps>;
