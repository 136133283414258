import { takeLatest, call } from 'redux-saga/effects';
import * as actions from './HomepageActions';
import { HomepageActionType } from './HomepageActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

function* fetchHomepage(): SagaIterator {
    yield call(callApi, {
        endpoint: 'homepage',
        onSuccess: (res: AxiosResponse): actions.HomepageAction => actions.createFetchHomepageSuccessAction(res.data),
        onError: actions.createFetchHomepageErrorAction,
    });
}

export default function* homepageSaga(): SagaIterator {
    yield takeLatest(HomepageActionType.FETCH_HOMEPAGE, fetchHomepage);
}
