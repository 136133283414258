import React, {
    FunctionComponent,
    CSSProperties,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
    useCallback,
} from 'react';
import { Event } from '../../../domain/editorial';
import classNames from 'clsx';
import { useHover } from '../../../hooks';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import AriaRole from '../../AriaRole';

export interface EventMapPinProps {
    /**
     * The list of events associated to this Pin.
     * Here we assume that all events have the same location code.
     * Consequently only the location code of the first Event will
     * be taken into account to determine the x,y coordinates of the
     * pin.
     */
    events: Event[];

    hoveredID?: string | undefined;

    isPulsating?: boolean;

    index?: number;

    onHover?: (events: Event[]) => Event;
}

interface PinPosition {
    x: number;
    y: number;
}

export const coordinatesForLocationCode: Record<string, PinPosition> = {
    C14: {
        x: 539.5,
        y: 171,
    },
    A16: {
        x: 316,
        y: 479,
    },
    A15: {
        x: 388,
        y: 462,
    },
    A11: {
        x: 324.5,
        y: 385,
    },
    A14: {
        x: 286.5,
        y: 467.5,
    },
    E2: {
        x: 969.5,
        y: 362.5,
    },
    E5: {
        x: 904.5,
        y: 337.5,
    },
    A2: {
        x: 300,
        y: 153,
    },
    E12: {
        x: 853,
        y: 266,
    },
    A9: {
        x: 290,
        y: 329,
    },
    A21: {
        x: 921.5,
        y: 219,
    },
    C4: {
        x: 648,
        y: 153,
    },
    C1: {
        x: 811.5,
        y: 164.5,
    },
    C10: {
        x: 588,
        y: 224.5,
    },
    B2: {
        x: 1044.5,
        y: 572.5,
    },
    A7: {
        x: 205,
        y: 314,
    },
    A5: {
        x: 152.5,
        y: 253.5,
    },
    A4: {
        x: 323,
        y: 173.5,
    },
    B1: {
        x: 1148.5,
        y: 600.5,
    },
    B4: {
        x: 1096.5,
        y: 474.5,
    },
    A6: {
        x: 264.5,
        y: 273.5,
    },
    E1: {
        x: 1032.5,
        y: 233.5,
    },
    F1: {
        x: 783,
        y: 232,
    },
    B10: {
        x: 742.5,
        y: 284,
    },
    C7: {
        x: 637,
        y: 229,
    },
    D1: {
        x: 522.5,
        y: 279.5,
    },
    D2: {
        x: 551.5,
        y: 289,
    },
    D4: {
        x: 611.5,
        y: 291.5,
    },
    D5: {
        x: 642.5,
        y: 301,
    },
    D6: {
        x: 707,
        y: 488,
    },
    D3: {
        x: 579,
        y: 281.5,
    },
    C16: {
        x: 498.5,
        y: 108,
    },
    A3: {
        x: 120,
        y: 181,
    },
    A1: {
        x: 303,
        y: 129,
    },
    A8: {
        x: 243,
        y: 354.5,
    },
    B3: {
        x: 941.5,
        y: 542.5,
    },
    B5: {
        x: 1034.5,
        y: 445.5,
    },
    E3: {
        x: 921,
        y: 418,
    },
    E4: {
        x: 891.5,
        y: 385.5,
    },
    E6: {
        x: 878.5,
        y: 306.5,
    },
    E13: {
        x: 808.5,
        y: 320.5,
    },
    E15: {
        x: 794,
        y: 273.5,
    },
    E8: {
        x: 946.5,
        y: 254,
    },
    E9: {
        x: 982,
        y: 232,
    },
    E7: {
        x: 928.5,
        y: 285,
    },
    F3: {
        x: 742.5,
        y: 206.5,
    },
    F5: {
        x: 676,
        y: 267.5,
    },
    C2: {
        x: 693.5,
        y: 204.5,
    },
    C18: {
        x: 591.5,
        y: 109,
    },
    C17: {
        x: 580.5,
        y: 141.5,
    },
    C5: {
        x: 586.5,
        y: 178.5,
    },
    D7: {
        x: 501.5,
        y: 333.5,
    },
    D8: {
        x: 542.5,
        y: 339.5,
    },
    D9: {
        x: 580,
        y: 347,
    },
    D10: {
        x: 617,
        y: 347,
    },
    D11: {
        x: 654,
        y: 347,
    },
    D12: {
        x: 652,
        y: 376.5,
    },
    D13: {
        x: 681.5,
        y: 354.5,
    },
    D14: {
        x: 510.5,
        y: 360.5,
    },
    D15: {
        x: 532.5,
        y: 374.5,
    },
    D16: {
        x: 561.5,
        y: 382.5,
    },
    D17: {
        x: 587.5,
        y: 395,
    },
    D18: {
        x: 619.5,
        y: 391.5,
    },
    D19: {
        x: 598.5,
        y: 422.5,
    },
    D20: {
        x: 640,
        y: 410.5,
    },
    D21: {
        x: 681.5,
        y: 398.5,
    },
    D22: {
        x: 669.5,
        y: 448.5,
    },
    D23: {
        x: 602.5,
        y: 455.5,
    },
    D24: {
        x: 636,
        y: 457.5,
    },
    D25: {
        x: 607.5,
        y: 488.5,
    },
    D26: {
        x: 635.5,
        y: 489.5,
    },
    D27: {
        x: 664,
        y: 486.5,
    },
    D28: {
        x: 629,
        y: 530.5,
    },
    A12: {
        x: 292.5,
        y: 395,
    },
    A13: {
        x: 286,
        y: 424,
    },
    A10: {
        x: 358,
        y: 404,
    },
    A17: {
        x: 356,
        y: 490,
    },
    A18: {
        x: 358,
        y: 542,
    },
    A19: {
        x: 326.5,
        y: 575,
    },
    A20: {
        x: 314,
        y: 542,
    },
    E14: {
        x: 823,
        y: 367,
    },
    F2: {
        x: 753.5,
        y: 232,
    },
    F4: {
        x: 663,
        y: 236.5,
    },
    F6: {
        x: 706.5,
        y: 267.5,
    },
    F7: {
        x: 699.5,
        y: 300.5,
    },
    F8: {
        x: 732.5,
        y: 316.5,
    },
    F9: {
        x: 715.5,
        y: 342.5,
    },
    C3: {
        x: 632,
        y: 181,
    },
    C6: {
        x: 654,
        y: 203,
    },
    C8: {
        x: 601,
        y: 196,
    },
    C9: {
        x: 614.5,
        y: 215,
    },
    C11: {
        x: 562.5,
        y: 196,
    },
    C12: {
        x: 539.5,
        y: 221,
    },
    C13: {
        x: 515.5,
        y: 231,
    },
    C15: {
        x: 517.5,
        y: 162,
    },
    C19: {
        x: 619.5,
        y: 121,
    },
    C20: {
        x: 652,
        y: 114,
    },
    E11: {
        x: 906.5,
        y: 189.5,
    },
};

function renderTooltip(
    locationCode: string,
    setDisplayTooltip: Dispatch<SetStateAction<boolean>>,
    events?: Event[]
): JSX.Element | null {
    if (events == null) {
        return null;
    }

    const pin = document.querySelector(`#map-pin-${locationCode}`);
    if (!pin) {
        return null;
    }
    const pinRect = pin.getBoundingClientRect();
    const style: CSSProperties = {
        top: pinRect.top + 10,
        left: pinRect.left + 10,
        position: 'fixed',
        zIndex: 999,
    };
    const tooltipId = `map-tooltip-${locationCode}`;
    const hideTooltip = (): void => {
        setDisplayTooltip(false);
    };

    const onEventSlideClick = (event: Event): void => {
        if (event.url) {
            window.open(event.url, '_blank');
        }
    };

    return ReactDOM.createPortal(
        /* eslint-disable-next-line jsx-a11y/no-static-element-interactions*/
        <div style={style} onMouseLeave={hideTooltip} onBlur={hideTooltip} role={AriaRole.BUTTON} id={tooltipId}>
            {events.map((event: Event, i: number): JSX.Element | null => {
                if (event === null || event.location == null) {
                    return null;
                }
                return (
                    <button className="arrow-box" key={i} onClick={(): void => onEventSlideClick(event)}>
                        <span>{dayjs(event.startingAt).format('DD MMMM YYYY')}</span>
                        <span>{event.location.name.toUpperCase()}</span>
                        <p>{event.title}</p>
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="arrow-right"
                            className="svg-inline--fa fa-arrow-right fa-w-14 "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                fill="currentColor"
                                d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"
                            ></path>
                        </svg>
                    </button>
                );
            })}
        </div>,
        document.querySelector('.App') as Element
    );
}

export const EventMapPin: FunctionComponent<EventMapPinProps> = ({
    events,
    isPulsating,
    hoveredID,
    index,
}: EventMapPinProps): JSX.Element | null => {
    const [hoverRef, isHovered] = useHover();
    const [displayTooltip, setDisplayTooltip] = useState(false);

    let locationCode: string | null = null;
    let position: PinPosition | null = null;
    if (events !== undefined && events.length >= 1 && events[0].location !== undefined) {
        locationCode = events[0].location.code;
        position = coordinatesForLocationCode[locationCode];
    }

    const isHighlighted: boolean = isHovered || !!isPulsating;

    const className = classNames('EventMapPin', {
        'EventMapPin-Hovered': isHighlighted,
    });

    const isCluster = events.length > 1;

    if (index == null) {
        index = 0;
    }

    if (hoveredID == null) {
        hoveredID = '';
    }

    const shouldPulsate = isPulsating && index.toString() === hoveredID;

    const handleScroll = useCallback((): void => {
        setDisplayTooltip(false);
    }, []);

    useEffect((): (() => void) => {
        window.addEventListener('scroll', handleScroll);
        return (): void => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect((): void => {
        if (isHovered) {
            setDisplayTooltip(true);
        }
        setTimeout((): void => {
            if (document.querySelector(`#map-tooltip-${locationCode}:hover`) === null) {
                setDisplayTooltip(false);
            }
        }, 1000);
    }, [isHovered, locationCode]);

    if (events.length < 1 || events[0].location == null || !position || !locationCode) {
        return null;
    }

    const pinId = `map-pin-${locationCode}`;

    return (
        <>
            {(isHovered || displayTooltip) && renderTooltip(locationCode, setDisplayTooltip, events)}
            <circle
                className={className}
                ref={hoverRef}
                fillRule="nonzero"
                cx={position.x}
                cy={position.y}
                r={isCluster ? 15 : 6}
                id={pinId}
            />
            {shouldPulsate && (
                <circle
                    className="pulse"
                    r="20"
                    fill="black"
                    cx={position.x}
                    cy={position.y}
                    width="30"
                    height="30"
                    preserveAspectRatio="none"
                    pointerEvents="none"
                />
            )}
            {isCluster && (
                <text width="48.265625" height="17" className="EventMapPin-ClusterText" fontSize={18} fill="white">
                    <tspan x={position.x} y={position.y} textAnchor="middle" alignmentBaseline="central">
                        {events.length}
                    </tspan>
                </text>
            )}
        </>
    );
};
