import { useCallback, useState, useEffect, useRef } from 'react';
import { useWindowWidth } from './useWindowSize';

type Rect = ClientRect | DOMRect;

export function useClientRect<T extends HTMLElement>(
    ...additionalDeps: any[]
): [Rect | null, (node: T | null) => void] {
    const [rect, setRect] = useState<Rect | null>(null);
    const node = useRef<T>();
    const windowWidth = useWindowWidth();
    const ref = useCallback((current: T | null): void => {
        if (current) {
            setRect(current.getBoundingClientRect());
            node.current = current;
        }
    }, []);

    const doSetRect = useCallback((): void => {
        if (node.current) {
            setRect(node.current.getBoundingClientRect());
        }
    }, [node]);

    useEffect((): (() => void) => {
        doSetRect();
        window.addEventListener('load', doSetRect);
        return (): void => {
            window.removeEventListener('load', doSetRect);
        };
    }, [windowWidth, node, doSetRect, ...additionalDeps]);

    return [rect, ref];
}
