import React, { Component, FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import { Store, compose } from 'redux';
import { NextPageContext } from 'next';
import split from 'lodash/split';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { WithTranslation } from 'react-i18next';
import Head from 'next/head';
import { connect } from 'react-redux';

import { withTranslation } from '../i18n';
import { createFetchHomepageAction } from '../modules/homepage';
import { AppState } from '../store/reducers';
import NewMovies from '../components/Home/NewMovies';
import Shortly from '../components/Home/Shortly';
import LastChance from '../components/Home/LastChance';
import News from '../components/Home/News';
import ThematicCycles from '../components/Home/ThematicCycles';
import MostSeen from '../components/Home/MostSeen';
import FocusOn from '../components/Home/FocusOn';
import Collections from '../components/Home/Collections';
import { HomepageState } from '../modules/homepage';
import { UIState } from '../modules/UI';
import { HttpStatusCode } from '../domain/HttpStatusCode';
import { Loader } from '../components/Loader';
import { MoviesWallProps } from '../components/Home/MoviesWall/MoviesWall';
import { InteractiveMap, InteractiveMapProps } from '../components/Home/InteractiveMap/InteractiveMap';
import { UserReviews } from '../components/Home/UserReviews';
import { Review } from '../domain/community';
import { createFetchLastReviewsAction } from '../modules/reviews';
import { HomeCurrentEvent } from '../components/Home/HomeCurrentEvent';

import Error from './_error';

import './index.scss';

const REDUCED_HEADER_MENU_HEIGHT = 45;

type StateProps = HomepageState & Pick<UIState, 'animations'> & { reviews?: Review[] };
interface IndexPageProps extends StateProps, WithTranslation {
    section: string;
}

const namespacesRequired: string[] = ['homepage', 'common', 'header', 'footer', 'current_event'];

function findSectionToDisplayFrom(urlPath: string): string | undefined {
    const pathParams = split(urlPath, '#', 2);
    return pathParams.length === 2 ? pathParams[1] : undefined;
}

function scrollToSection(prevProps: IndexPageProps, props: IndexPageProps): void {
    if (
        (prevProps.isLoading !== props.isLoading || prevProps.section !== props.section) &&
        props.section &&
        !props.isLoading
    ) {
        const section = document.getElementById(props.section);
        if (section) {
            const sectionPosition = section.getBoundingClientRect().top;
            const offsetPosition = sectionPosition + window.pageYOffset - REDUCED_HEADER_MENU_HEIGHT;
            window.scrollTo({ top: offsetPosition });
        }
    }
}

class IndexPage extends Component<IndexPageProps> {
    public constructor(props: IndexPageProps) {
        super(props);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static async getInitialProps(ctx: NextPageContext & { store: Store }): Promise<any> {
        ctx.store.dispatch(createFetchHomepageAction());
        ctx.store.dispatch(createFetchLastReviewsAction());

        const section = findSectionToDisplayFrom(ctx.asPath || '');

        return {
            section,
            namespacesRequired,
            layout: 'default',
        };
    }

    public componentDidUpdate(prevProps: IndexPageProps): void {
        scrollToSection(prevProps, this.props);
    }

    public render(): JSX.Element | null {
        const MoviesWall = dynamic<MoviesWallProps>(
            (): Promise<FunctionComponent<MoviesWallProps>> =>
                import('../components/Home/MoviesWall').then(
                    (moviesWall): FunctionComponent<MoviesWallProps> => moviesWall.MoviesWall
                ),
            { ssr: false, loading: (): JSX.Element => <Loader /> }
        );

        const { t, data: homepage, tReady, i18n, isLoading, error, animations, reviews } = this.props;

        if (error) {
            return <Error statusCode={get(error, 'status', HttpStatusCode.INTERNAL_SERVER_ERROR)} />;
        }

        if (!homepage) {
            if (isLoading) {
                return (
                    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', minHeight: '100%' }}>
                        <Loader />
                    </div>
                );
            } else {
                return <Error statusCode={500} />;
            }
        }

        const {
            recentlyAdded,
            news,
            upcoming,
            lastChance,
            cycle,
            focus,
            mostSeen,
            collection,
            wall,
            event,
            currentEvent,
        } = homepage!;

        const interactiveMapProps: InteractiveMapProps = {
            ...event!,
            t,
            i18n,
            tReady,
        };

        return (
            <>
                <Head>
                    <title>{t('metaTitle')}</title>
                    <meta name="description" content={t('metaDescription')} />
                </Head>
                <div className="Home">
                    <section className="Sentence">
                        <p>
                            <span>{t('title')}</span>
                        </p>
                    </section>
                    <MoviesWall t={t} newsWallLayer={wall} animations={animations} />

                    {currentEvent &&
                        currentEvent.currentEvents !== undefined &&
                        currentEvent.currentEvents.length > 0 && <HomeCurrentEvent t={t} currentEvent={currentEvent} />}

                    <NewMovies movieListLayer={recentlyAdded} t={t} />
                    <div className="column-right-bloc MoviesActuContainer">
                        <svg width="216px" height="257px" viewBox="0 0 216 257" className="rect">
                            <rect
                                transform="translate(137, 128) rotate(160) translate(-137, -128) "
                                x="112"
                                y="1"
                                width="50"
                                height="255"
                            />
                            <rect
                                transform="translate(108, 121) rotate(-225) translate(-108, -121) "
                                x="83"
                                y="-6"
                                width="50"
                                height="255"
                            />
                        </svg>

                        <div className="MaxWidthSection">
                            <div>
                                <Shortly movieListLayer={upcoming} t={t} />
                                <LastChance movieListLayer={lastChance} t={t} />
                            </div>
                            <News newsLayer={news} t={t} />
                        </div>
                    </div>
                    <ThematicCycles cycleLayer={cycle} t={t} language={i18n.language as any} />
                    <FocusOn focus={focus} t={t} />
                    <MostSeen movieListLayer={mostSeen} t={t} language={i18n.language} />
                    <InteractiveMap {...interactiveMapProps} />
                    <Collections collectionLayer={collection} t={t} />
                    <UserReviews reviews={reviews} t={t} language={i18n.language} />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    ...state.homepage,
    ...pick(state.UI, 'animations'),
    reviews: state.reviews.data,
});

export default compose(
    connect(mapStateToProps),
    withTranslation(namespacesRequired)
)(IndexPage);
