import { Homepage } from '../../domain/editorial';
import { HomepageActionType } from './HomepageActionType';
import { HomepageAction } from './HomepageActions';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export type HomepageState = FetchState<Homepage>;

export const initialState: HomepageState = {
    isLoading: false,
};

export const homepageReducer = (state: HomepageState = initialState, action: HomepageAction): HomepageState => {
    switch (action.type) {
        case HomepageActionType.FETCH_HOMEPAGE:
            return {
                ...state,
                isLoading: true,
            };

        case HomepageActionType.FETCH_HOMEPAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as Homepage,
            };

        case HomepageActionType.FETCH_HOMEPAGE_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
