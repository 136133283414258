import { HomepageActionType } from './HomepageActionType';
import { Homepage } from '../../domain/editorial';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export type HomepageAction = FetchAction<HomepageActionType, Homepage>;

export function createFetchHomepageAction(): HomepageAction {
    return { type: HomepageActionType.FETCH_HOMEPAGE };
}

export function createFetchHomepageSuccessAction(homepage: Homepage): HomepageAction {
    return {
        type: HomepageActionType.FETCH_HOMEPAGE_SUCCESS,
        payload: homepage,
    };
}

export function createFetchHomepageErrorAction(error: RequestError): HomepageAction {
    return {
        type: HomepageActionType.FETCH_HOMEPAGE_ERROR,
        payload: error,
    };
}
