import React from 'react';
import { Review as ReviewType } from '../../../domain/community';
import classNames from 'clsx';
import { ReviewUpdateAction } from '../../../modules/reviews';
import { ActionCreator } from 'redux';
import { UIAction } from '../../../modules/UI';
import { FrontUser } from '../../../domain/user';
import { WithT } from 'i18next';
import { Link } from '../A11y/Link';
import capitalize from 'lodash/capitalize';
import { MoviePoster } from '../MoviePoster';
import { Movie } from '../../../domain/work';

export interface ReviewProps extends WithT {
    review: ReviewType;
    setReviewToDisplay: (review: ReviewType | null) => void;
    editReview: ActionCreator<ReviewUpdateAction>;
    toggleAddReviewModal: ActionCreator<UIAction>;
    toggleConfirmReviewDeletionModal: ActionCreator<UIAction>;
    frontUser?: FrontUser;
    className?: string;
    truncateAfter?: number;
    doDisplayAuthor?: boolean;
}

export const Review = (props: ReviewProps): JSX.Element | null => {
    if (!props.review.review || props.review.review.trim() === '') {
        return null;
    }
    const truncateAfter = props.truncateAfter || 400;
    const reviewText =
        props.review.review.length < truncateAfter
            ? props.review.review
            : `${props.review.review.substr(0, truncateAfter)}...`;
    const reviewerName = `${props.review.frontUser.givenName} ${props.review.frontUser.lastName}`;
    const reviewerIsLoggedUser = props.frontUser != null && props.review.frontUser.id === props.frontUser.id;
    const className: string = classNames('Review', props.className, { mine: reviewerIsLoggedUser });
    const movieLink = { pathname: '/movie', query: { id: props.review.movie.id } };

    let detailsButtonLabel = '';
    if (reviewerIsLoggedUser) {
        detailsButtonLabel = props.t('common:read_my_review');
    } else if (props.doDisplayAuthor !== undefined && props.doDisplayAuthor === false) {
        detailsButtonLabel = props.t('common:read_review');
    } else {
        detailsButtonLabel = props.t('common:read_review_from', { reviewerName });
    }

    return (
        <article className={className}>
            <div className="Review-Contents">
                <Link href={movieLink} className="Review-Contents-Poster">
                    <MoviePoster movie={props.review.movie as Movie} t={props.t} width={120} height={160} />
                </Link>
                <div className="Review-Contents-Text">
                    {props.review.title && <h3 className="Review-Title">{props.review.title}</h3>}
                    <p>{reviewText}</p>
                    <button
                        type="button"
                        className="Review-Read link-dotted"
                        title={detailsButtonLabel}
                        onClick={(): void => props.setReviewToDisplay(props.review)}
                    >
                        {detailsButtonLabel}
                    </button>
                </div>
            </div>
            <div className="Review-Meta">
                {capitalize(props.t('common:about'))}
                <Link href={movieLink} className="Review-MovieLink">
                    {props.review.movie.title}
                </Link>
            </div>
        </article>
    );
};
