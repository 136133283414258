import React, { FunctionComponent, useState, useEffect } from 'react';
import { Link } from '../../../i18n';
import { WithT } from 'i18next';
import { Collection, CollectionLayer } from '../../../domain/editorial';
import { getCollectionMainImageUrl } from '../../../domain/utils/CollectionUtils';
import { useWindowWidth } from '../../../hooks';
import classNames from 'clsx';

interface ClipBoxSvgProps {
    imageUrl: string;
}

const ClipBoxSvg1 = ({ imageUrl }: ClipBoxSvgProps): JSX.Element => {
    const minWidth = 1440,
        height = 700;
    const windowWidth = useWindowWidth();

    const [width, setWidth] = useState(minWidth);
    useEffect((): void => {
        setWidth(Math.max(minWidth, windowWidth || minWidth));
    }, [windowWidth]);
    const viewBox = `0 0 ${width} ${height}`,
        polygon = `0 ${height},${width} ${height - 60},${width} 0,0 60`,
        decorationsTransform = `translate(${width - 340},50)`;
    return (
        <svg
            viewBox={viewBox}
            height={height}
            width={width}
            preserveAspectRatio="xMinYMid slice"
            aria-hidden="true"
            style={{ maxHeight: `${height}px` }}
        >
            <defs>
                <clipPath id="clipCollections1" aria-hidden="true">
                    <polygon points={polygon} aria-hidden="true" />
                </clipPath>
            </defs>
            <g aria-hidden="true" clipPath="url(#clipCollections1)">
                <rect width={width} height={height} aria-hidden="true" id="rect-back-collection1" />
                <g transform={decorationsTransform} aria-hidden="true">
                    <g>
                        <rect
                            aria-hidden="true"
                            opacity=".1"
                            transform="translate(216, 264) rotate(-135) translate(-216, -264) "
                            x="166"
                            y="9"
                            width="100"
                            height="510"
                        />
                        <rect
                            aria-hidden="true"
                            opacity=".1"
                            transform="translate(282, 245) rotate(-150) translate(-282, -245) "
                            x="232"
                            y="-9"
                            width="100"
                            height="510"
                        />
                    </g>
                </g>
                <image
                    width={width - 510}
                    height="100%"
                    preserveAspectRatio="xMaxYMid slice"
                    xlinkHref={imageUrl}
                    aria-hidden="true"
                />
            </g>
        </svg>
    );
};

const ClipBoxSvg2 = ({ imageUrl }: ClipBoxSvgProps): JSX.Element => {
    const minWidth = 1440,
        height = 700;
    const windowWidth = useWindowWidth();
    const [width, setWidth] = useState(minWidth);
    useEffect((): void => {
        setWidth(Math.max(minWidth, windowWidth || minWidth));
    }, [windowWidth]);
    const viewBox = `0 0 ${width} ${height}`,
        polygon = `0 ${height - 60},${width} ${height},${width} 60,0 0`;

    return (
        <svg viewBox={viewBox} preserveAspectRatio="xMinYMid slice" aria-hidden="true">
            <defs>
                <clipPath id="clipCollections2" aria-hidden="true">
                    <polygon points={polygon} aria-hidden="true" />
                </clipPath>
            </defs>
            <g aria-hidden="true" clipPath="url(#clipCollections2)">
                <rect width={height} height={height} aria-hidden="true" id="rect-back-collection2" />
                <g transform="translate(350,100) scale(-1, 1)" aria-hidden="true">
                    <g>
                        <rect
                            aria-hidden="true"
                            opacity=".1"
                            transform="translate(216, 264) rotate(-135) translate(-216, -264) "
                            x="166"
                            y="9"
                            width="100"
                            height="510"
                        />
                        <rect
                            aria-hidden="true"
                            opacity=".1"
                            transform="translate(282, 245) rotate(-150) translate(-282, -245) "
                            x="232"
                            y="-9"
                            width="100"
                            height="510"
                        />
                    </g>
                </g>
                <image
                    transform="translate(510,0)"
                    width={width - 510}
                    height="100%"
                    preserveAspectRatio="xMinYMid slice"
                    xlinkHref={imageUrl}
                    aria-hidden="true"
                />
            </g>
        </svg>
    );
};

export interface CollectionsProps extends WithT {
    collectionLayer?: CollectionLayer;
}

function renderClipBoxSvg(collection: Collection, idx: number): JSX.Element {
    const clipBoxSvgProps: ClipBoxSvgProps = {
        imageUrl: getCollectionMainImageUrl(collection) || '',
    };
    const ClipBoxSvg: FunctionComponent<ClipBoxSvgProps> = (idx + 1) % 2 === 0 ? ClipBoxSvg2 : ClipBoxSvg1;
    return <ClipBoxSvg {...clipBoxSvgProps} />;
}

const Collections = ({ t, collectionLayer }: CollectionsProps): JSX.Element | null => {
    if (collectionLayer == null || collectionLayer.collections == null || collectionLayer.collections.length < 1) {
        return null;
    }

    const title = collectionLayer.title || t('collections');

    return (
        <section className="Collections">
            <span className="Collections-Anchor" id="collections"></span>
            <svg width="86px" height="130px" viewBox="0 0 86 130" className="rect only-mobile">
                <polygon
                    transform="translate(55, 64) rotate(-15) translate(-55, -64) "
                    points="43 1 68 1 68 128 43 128"
                />
                <polygon
                    transform="translate(43, 66) rotate(-30) translate(-43, -66) "
                    points="30 2.5 55 2.5 55 130 30 130"
                />
            </svg>
            <h2 className="Collections-title">{title}</h2>
            {collectionLayer.collections!.slice(0, 2).map(
                (collection: Collection, i: number): JSX.Element => {
                    const collectionUrl = `/collection/${collection.id}`;
                    return (
                        <div className="clip-box" key={i}>
                            {renderClipBoxSvg(collection, i)}
                            <div className="container">
                                <div className="content">
                                    <div className="box">
                                        <h3 className={classNames('title', 'title-3')}>{collection.title}</h3>
                                        <p>{collection.description}</p>
                                    </div>
                                    <Link href={collectionUrl}>
                                        <a className="is-stroke-effect button is-info">
                                            <span>{t('discover')}</span>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    );
                }
            )}
            <svg
                width="253px"
                height="216px"
                viewBox="0 0 253 216"
                className="rect only-desktop is-outlined"
                aria-hidden="true"
            >
                <g transform="translate(-922, -6830)" aria-hidden="true">
                    <g
                        transform="translate(1042, 6949) rotate(-30) translate(-1042, -6949) translate(948, 6819)"
                        aria-hidden="true"
                    >
                        <rect
                            transform="translate(117, 129) rotate(-15) translate(-117, -129) "
                            x="92.5"
                            y="2"
                            width="49"
                            height="254"
                            aria-hidden="true"
                        />
                        <rect
                            transform="translate(94, 140) rotate(-35) translate(-94, -140) "
                            x="69"
                            y="13.5"
                            width="49"
                            height="254"
                            aria-hidden="true"
                        />
                    </g>
                </g>
            </svg>
        </section>
    );
};

export default Collections;
