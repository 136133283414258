import React from 'react';
import Slider from 'react-slick';
import { MovieSlide } from '../../common/MovieSlide';
import { WithT } from 'i18next';
import { MovieListLayer } from '../../../domain/editorial';
import { MovieSlideData } from '../../../domain/work';
import { RightArrow, LeftArrow } from '../../common/Slider';
import { commonMovieSliderSettings } from '../../../utils/SliderUtils';
import classNames from 'clsx';

export interface MostSeenProps extends WithT {
    language: string;
    movieListLayer?: MovieListLayer;
}

const MostSeen = ({ movieListLayer, t, language }: MostSeenProps): JSX.Element | null => {
    const title = t('mostSeen');

    if (movieListLayer == null || movieListLayer.movies == null || movieListLayer.movies.length < 1) {
        return null;
    }

    const sliderSettings = {
        ...commonMovieSliderSettings,
        nextArrow: <RightArrow t={t} />,
        prevArrow: <LeftArrow t={t} />,
    };

    const heading2ImgSrc = `/static/img/Home/MostSeen/title-${language}.svg`;
    return (
        <section className="MostSeen MaxWidthSection">
            <h2 className={classNames('title', 'title-2')}>
                <img src={heading2ImgSrc} className={language === 'en' ? 'only-lang-en' : ''} alt={title} />
            </h2>
            <Slider {...sliderSettings} className="slider-horizontal">
                {movieListLayer.movies!.map(
                    (movie: MovieSlideData, i: number): JSX.Element => (
                        <MovieSlide movie={movie} key={i} parentId={undefined} t={t} />
                    )
                )}
            </Slider>
        </section>
    );
};

export default MostSeen;
