import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import Slider from 'react-slick';
import { WithT } from 'i18next';
import { RightArrow, LeftArrow } from '../../common/Slider';
import { Review as ReviewType } from '../../../domain/community';
import map from 'lodash/map';
import { ReadReviewModal } from '../../MovieBrochure/MovieReviews/ReadReviewModal';
import { FrontUser } from '../../../domain/user';
import { ActionCreator } from 'redux';
import {
    UIAction,
    createToggleAddReviewModalAction,
    createToggleConfirmReviewDeletionModalAction,
} from '../../../modules/UI';
import { ReviewUpdateAction, createEditReviewAction } from '../../../modules/reviews';
import { connect } from 'react-redux';
import { AppState } from '../../../store/reducers';
import find from 'lodash/find';
import { ConfirmReviewDeletionModal } from '../../MovieBrochure/MovieReviews/ConfirmReviewDeletionModal';
import { Review } from '../../common/Review';
import { MovieAction, createFetchMovieSuccessAction } from '../../../modules/movie';
import Breakpoint from '../../Breakpoint';
import classNames from 'clsx';

import './UserReviews.scss';

interface OwnProps extends WithT {
    reviews?: ReviewType[];
    language: string;
}

interface DispatchProps {
    setMovie: ActionCreator<MovieAction>;
    toggleAddReviewModal: ActionCreator<UIAction>;
    toggleConfirmReviewDeletionModal: ActionCreator<UIAction>;
    editReview: ActionCreator<ReviewUpdateAction>;
}

interface StateProps {
    frontUser?: FrontUser;
    reviewDeletionConfirmationModalIsOpen: boolean;
}

type UserReviewsProps = OwnProps & StateProps & DispatchProps;

const renderReview = (
    review: ReviewType,
    props: UserReviewsProps,
    setReviewToDisplay: Dispatch<SetStateAction<ReviewType | null>>
): JSX.Element | null => {
    if (!review.review || review.review.trim() === '') {
        return null;
    }

    return (
        <Review
            key={review.id}
            setReviewToDisplay={(review: ReviewType | null): void => {
                if (review) {
                    props.setMovie(review.movie);
                }
                setReviewToDisplay(review);
            }}
            review={review}
            toggleAddReviewModal={props.toggleAddReviewModal}
            toggleConfirmReviewDeletionModal={props.toggleConfirmReviewDeletionModal}
            editReview={props.editReview}
            frontUser={props.frontUser}
            className="UserReviews-Review"
            truncateAfter={Breakpoint.getCurrent() === Breakpoint.MOBILE ? 100 : 200}
            t={props.t}
            doDisplayAuthor={false}
        />
    );
};

const _UserReviews = (props: UserReviewsProps): JSX.Element => {
    const [reviewToDisplay, setReviewToDisplay] = useState<ReviewType | null>(null);

    useEffect((): void => {
        if (props.reviews && props.reviews.length > 0 && reviewToDisplay) {
            setReviewToDisplay(find(props.reviews, { id: reviewToDisplay.id }) || null);
        }
    }, [props.reviews, reviewToDisplay]);

    const settings = {
        dots: false,
        infinite: false,
        draggable: false,
        speed: 300,
        focusOnSelect: true,
        variableWidth: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <RightArrow t={props.t} />,
        prevArrow: <LeftArrow t={props.t} />,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 880,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const heading2ImgSrc = `/static/img/UserReviews/title-${props.language}.svg`;

    return (
        <section className="UserReviews">
            <svg width="86px" height="130px" viewBox="0 0 86 130" className="rect only-mobile">
                <polygon
                    transform="translate(55, 64) rotate(-15) translate(-55, -64) "
                    points="43 1 68 1 68 128 43 128"
                />
                <polygon
                    transform="translate(43, 66) rotate(-30) translate(-43, -66) "
                    points="30 2.5 55 2.5 55 130 30 130"
                />
            </svg>
            <h2 className={classNames('title', 'title-2')}>
                <img src={heading2ImgSrc} alt={props.t('userReviews')} />
            </h2>

            <ConfirmReviewDeletionModal
                t={props.t}
                isOpen={props.reviewDeletionConfirmationModalIsOpen}
                onClose={props.toggleConfirmReviewDeletionModal}
                onReviewDeleted={(): void => {
                    setReviewToDisplay(null);
                }}
            />

            <ReadReviewModal
                review={reviewToDisplay}
                isOpen={reviewToDisplay !== null}
                onClose={(): void => {
                    setReviewToDisplay(null);
                }}
                t={props.t}
                toggleConfirmReviewDeletionModal={props.toggleConfirmReviewDeletionModal}
                toggleAddReviewModal={props.toggleAddReviewModal}
                editReview={props.editReview}
                frontUser={props.frontUser}
            />
            <Slider {...settings}>
                {map(props.reviews, (review: ReviewType): JSX.Element | null =>
                    renderReview(review, props, setReviewToDisplay)
                )}
            </Slider>
        </section>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    frontUser: state.profile.data,
    reviewDeletionConfirmationModalIsOpen: state.UI.reviewDeletionConfirmationModalIsOpen,
});

const mapDispatchToProps: DispatchProps = {
    setMovie: createFetchMovieSuccessAction,
    editReview: createEditReviewAction,
    toggleAddReviewModal: createToggleAddReviewModalAction,
    toggleConfirmReviewDeletionModal: createToggleConfirmReviewDeletionModalAction,
};

export const UserReviews = connect(
    mapStateToProps,
    mapDispatchToProps
)(_UserReviews);
