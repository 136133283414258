import { Collection } from '../editorial';
import find from 'lodash/find';
import { hasDefinedUrl } from './AssetFileUtils';
import { AssetFile } from '../media';

/**
 * Randomly select a movie mainImage inside a collection's movies to be the
 * Collection mainImage itself.
 * Or undefined if no image could be found.
 * @param collection
 */
export function getCollectionMainImageUrl(collection?: Collection): string | undefined {
    if (collection == null || collection.mainImage == null) {
        return undefined;
    }
    const foundAssetFile: AssetFile | undefined = find(collection.mainImage.files, hasDefinedUrl);
    return foundAssetFile ? foundAssetFile.url : undefined;
}
