/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from '../../../i18n';
import { Settings as SliderSettings } from 'react-slick';
import { MovieSlide } from '../../common/MovieSlide';
import classNames from 'clsx';
import { WithT } from 'i18next';
import c from 'lodash/upperFirst';
import debounce from 'lodash/debounce';
import Breakpoint from '../../Breakpoint';
import { HorizontalSlider, RightArrow, LeftArrow } from '../../common/Slider';
import { MovieListLayer } from '../../../domain/editorial';
import { MovieSlideData } from '../../../domain/work';
import { SortOption } from '../../../domain/listings';
import { commonMovieSliderSettings } from '../../../utils/SliderUtils';

export interface NewMoviesProps extends WithT {
    movieListLayer?: MovieListLayer;
}

const NewMovies: React.FC<NewMoviesProps> = ({ t, movieListLayer }) => {
    const [isActive, setIsActive] = useState(false);

    const handleBeforeSlideChange = useCallback((slideIndexBeforeChange: number, slideIndexAfterChange: number) => {
        if (slideIndexBeforeChange === 0 && slideIndexAfterChange === 1) {
            setIsActive(true);
        } else if (slideIndexBeforeChange === 1 && slideIndexAfterChange === 0) {
            setIsActive(false);
        }
    }, []);

    const handleWindowResize = useCallback(() => {
        // Force re-render
        setIsActive(prevState => prevState);
    }, []);

    useEffect(() => {
        const debouncedHandleWindowResize = debounce(handleWindowResize, 100);
        window.addEventListener('resize', debouncedHandleWindowResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleWindowResize);
        };
    }, [handleWindowResize]);

    const sliderSettings: SliderSettings = {
        ...commonMovieSliderSettings,
        beforeChange: handleBeforeSlideChange,
        nextArrow: <RightArrow t={t} />,
        prevArrow: <LeftArrow t={t} />,
    };

    if (!movieListLayer || !movieListLayer.movies || movieListLayer.movies.length < 1) {
        return null;
    }

    const title: string = t('newMovies');
    const seeMoreSlide: string = c(t('common:seeMore'));
    const seeMoreLink: string = t('common:seeMore');
    const seeMoreLinkTitle: string = t('seeMoreLinkTitle');

    const renderSeeMoreSlide = () => {
        if (Breakpoint.getCurrent() === Breakpoint.MOBILE) {
            return (
                <Link href={{ pathname: '/movies', query: { sort: SortOption.LAST_ADDED } }}>
                    <a className="is-stroke-effect button">
                        <span>{seeMoreSlide}</span>
                    </a>
                </Link>
            );
        }
        return null;
    };

    const renderSeeMoreLink = () => {
        if (Breakpoint.getCurrent().isWiderThan(Breakpoint.MOBILE)) {
            return (
                <Link href={{ pathname: '/movies', query: { sort: SortOption.LAST_ADDED } }}>
                    <a className="link-dotted" title={seeMoreLinkTitle}>
                        {seeMoreLink}
                    </a>
                </Link>
            );
        }
        return null;
    };

    return (
        <section className="News">
            <h2 className={classNames('title', 'title-2', { 'is-active': isActive })}>
                <span className="first-letter">{title.charAt(0)}</span>
                <span>{title.slice(1)}</span>
                <span className="visually-hidden">{title}</span>
            </h2>
            <HorizontalSlider {...sliderSettings} className="slider-horizontal">
                {movieListLayer.movies.map((movie: MovieSlideData, i: number) => (
                    <MovieSlide key={i} movie={movie} parentId="news" t={t} />
                ))}
                <div>{renderSeeMoreSlide()}</div>
            </HorizontalSlider>
            {renderSeeMoreLink()}
        </section>
    );
};

export default NewMovies;
