import React, { CSSProperties, useEffect, useState } from 'react';
import { WithT } from 'i18next';

import { Focus } from '../../../domain/editorial';
import { getFocusMainImageUrl } from '../../../domain/utils/FocusUtils';
import { useClientRect } from '../../../hooks/useClientRect';
import { useWindowWidth } from '../../../hooks';
import Breakpoint from '../../Breakpoint';
import classNames from 'clsx';

export interface FocusOnProps extends WithT {
    focus?: Focus;
}

const ClippedBackground = ({ image }: { image?: string }): JSX.Element => {
    const minWidth = 1440,
        height = 810;
    const windowWidth = useWindowWidth();

    const [width, setWidth] = useState(minWidth);
    useEffect((): void => {
        setWidth(Math.max(minWidth, windowWidth || minWidth));
    }, [windowWidth]);

    const viewBox = `0 0 ${width} ${height}`,
        polygon = `0 ${height - 60},${width} ${height},${width} 60,0 0`;

    return (
        <svg viewBox={viewBox} aria-hidden="true" style={{ maxHeight: '810px' }} preserveAspectRatio="xMinYMid slice">
            <defs>
                <clipPath id="clipFocusOn">
                    <polygon points={polygon} />
                </clipPath>
            </defs>
            <g aria-hidden="true" clipPath="url(#clipFocusOn)">
                <rect width={width} height={height} aria-hidden="true" />
                {image && (
                    <image
                        width={width}
                        height={height}
                        opacity="0.75"
                        preserveAspectRatio="xMinYMid slice"
                        xlinkHref={image}
                        aria-hidden="true"
                    />
                )}
            </g>
        </svg>
    );
};

const FocusOn = ({ t, focus }: FocusOnProps): JSX.Element | null => {
    const [rect, ref] = useClientRect(focus);

    if (focus == null) {
        return null;
    }

    const title: string = focus.title || t('focus');
    const mainImageUrl = getFocusMainImageUrl(focus);

    const isDesktop: boolean = Breakpoint.getCurrent().isWiderThan(Breakpoint.DESKTOP);
    const style: CSSProperties = { height: rect !== null && isDesktop ? `${rect.height}px` : 'auto' };

    return (
        <section className="FocusOn" style={style}>
            <svg width="205px" height="279px" viewBox="0 0 205 279" className="rect" aria-hidden="true">
                <g transform="translate(-56, -580)" aria-hidden="true">
                    <g transform="translate(-144, 118)" aria-hidden="true">
                        <g
                            transform="translate(292, 589) rotate(-30) translate(-292, -589) translate(143, 480)"
                            aria-hidden="true"
                        >
                            <rect
                                transform="translate(107, 109) rotate(-495) translate(-107, -109) "
                                x="82"
                                y="-18"
                                width="50"
                                height="255"
                                aria-hidden="true"
                            />
                            <rect
                                transform="translate(174, 118) rotate(-120) translate(-174, -118) "
                                x="149"
                                y="-9"
                                width="50"
                                height="255"
                                aria-hidden="true"
                            />
                        </g>
                    </g>
                </g>
            </svg>
            <h2 className={classNames('title', 'title-2')}>
                <span aria-hidden="true" className="first-letter">
                    {title.charAt(0)}
                </span>
                <span aria-hidden="true">{title.slice(1)}</span>
                <span className="visually-hidden">{title}</span>
            </h2>
            <div className="clip-box">
                <ClippedBackground image={mainImageUrl} />
                <div className="container" ref={ref}>
                    <div className="content">
                        <div className="box">
                            {focus.subhead && <h3 className={classNames('title', 'title-3')}>{focus.subhead}</h3>}
                            <p>{focus.description}</p>
                        </div>
                        <a
                            href={focus.externalUrl}
                            className="is-stroke-effect button is-info"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>{t('discover')}</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FocusOn;
