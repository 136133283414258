import cn from 'clsx';
import React from 'react';
import Slider from 'react-slick';
import { MovieSlide } from '../../common/MovieSlide';
import { WithT } from 'i18next';
import { MovieSlideData } from '../../../domain/work';
import { MovieListLayer } from '../../../domain/editorial';
import classNames from 'clsx';

export interface ShortlyProps extends WithT {
    movieListLayer?: MovieListLayer;
}

const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToScroll: 3,
    initialSlide: 0,
    draggable: false,
    variableWidth: true,
};

const Shortly = ({ movieListLayer, t }: ShortlyProps): JSX.Element | null => {
    if (movieListLayer == null || movieListLayer.movies == null || movieListLayer.movies.length < 1) {
        return null;
    }

    const title: string = t('upcoming');

    return (
        <section className="Shortly">
            <h2 className={classNames('title', 'title-3')}>
                <span className={cn('first-letter', 'noIndent')}>{title.charAt(0)}</span>
                <span>{title.slice(1)}</span>
                <span className="visually-hidden">{title}</span>
            </h2>
            <Slider {...settings} className="slider-horizontal">
                {movieListLayer.movies.slice(0, 4).map(
                    (movie: MovieSlideData, i: number): JSX.Element => (
                        <MovieSlide key={i} movie={movie} parentId="Upcoming" t={t} />
                    )
                )}
            </Slider>
        </section>
    );
};

export default Shortly;
