import React, { Component } from 'react';
import { MovieSlideData } from '../../../domain/work';
import { MoviePoster } from '../MoviePoster';
import { WithT } from 'i18next';
import debounce from 'lodash/debounce';

const MOBILE_BREAKPOINT = 500;

interface ResponsiveNumber {
    desktop: number;
    mobile: number;
}

interface StackedMoviePostersProps extends WithT {
    movies: MovieSlideData[];
    limit?: number | ResponsiveNumber;
    width: number | ResponsiveNumber;
    height: number | ResponsiveNumber;
    spacing: number | ResponsiveNumber;
    isHovering: boolean;
}

interface StackedMoviePostersState {
    postersOffset: number;
    mobileDisplay: boolean;
}

export class StackedMoviePosters extends Component<StackedMoviePostersProps, StackedMoviePostersState> {
    private container: HTMLDivElement | null = null;
    public state = {
        postersOffset: 0,
        mobileDisplay: false,
    };

    public constructor(props: StackedMoviePostersProps) {
        super(props);
        this.refContainer = this.refContainer.bind(this);
        this.handleResize = debounce(this.handleResize.bind(this), 300);
    }

    private refContainer(el: HTMLDivElement | null): void {
        this.container = el;
    }

    private getResponsiveValue(value: number | ResponsiveNumber): number {
        if (typeof value === 'number') {
            return value;
        }

        return this.state.mobileDisplay ? value.mobile : value.desktop;
    }

    private getPostersOffset(): number {
        if (this.props.movies === undefined || this.props.movies.length <= 1 || this.container === null) {
            return 0;
        }

        const totalMoviesDisplayed = this.props.limit
            ? Math.min(this.getResponsiveValue(this.props.limit), this.props.movies.length)
            : this.props.movies.length;

        const spacing = this.getResponsiveValue(this.props.spacing),
            initialWidth = this.getResponsiveValue(this.props.width),
            initialHeight = this.getResponsiveValue(this.props.height),
            ratio = initialWidth / initialHeight;

        let currentWidth,
            currentHeight,
            totalWidth = 0;

        for (let i = 0; i < totalMoviesDisplayed; i++) {
            currentHeight = initialHeight - i * spacing;
            currentWidth = currentHeight * ratio;
            totalWidth += currentWidth;
        }

        const diff = totalWidth - this.container.clientWidth - (this.props.isHovering ? 10 : 0);
        return diff / (totalMoviesDisplayed - 1);
    }

    public componentDidMount(): void {
        this.setState({
            mobileDisplay: window.innerWidth < MOBILE_BREAKPOINT,
            postersOffset: this.getPostersOffset(),
        });
        window.addEventListener('resize', this.handleResize);
    }

    public componentDidUpdate(prevProps: StackedMoviePostersProps, prevState: StackedMoviePostersState): void {
        if (this.state.mobileDisplay !== prevState.mobileDisplay || this.props.isHovering !== prevProps.isHovering) {
            this.setState({ postersOffset: this.getPostersOffset() });
        }
    }

    private handleResize(): void {
        this.setState({
            mobileDisplay: window.innerWidth < MOBILE_BREAKPOINT,
            postersOffset: this.getPostersOffset(),
        });
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    public render(): JSX.Element | null {
        const { movies, width, height, spacing, limit, t } = this.props;
        if (!movies) {
            return null;
        }
        const responsiveSpacing = this.getResponsiveValue(spacing),
            responsiveLimit = limit ? this.getResponsiveValue(limit) : movies.length,
            responsiveWidth = this.getResponsiveValue(width),
            responsiveHeight = this.getResponsiveValue(height),
            ratio = responsiveWidth / responsiveHeight;

        return (
            <div className="StackedMoviePosters" ref={this.refContainer}>
                {movies.slice(0, responsiveLimit).map(
                    (movie: MovieSlideData, i: number): JSX.Element => {
                        const h = responsiveHeight - i * responsiveSpacing,
                            w = h * ratio;
                        return (
                            <MoviePoster
                                movie={movie}
                                key={i}
                                width={w}
                                height={h}
                                className="StackedMoviePoster"
                                style={{
                                    transform: `translateX(-${(i === 0
                                        ? 0
                                        : Math.max(0, this.state.postersOffset) * i) +
                                        (this.props.isHovering ? 10 : 0)}px)`,
                                    width: `${w}px`,
                                    height: `${h}px`,
                                    zIndex: responsiveLimit - i,
                                }}
                                t={t}
                            />
                        );
                    }
                )}
            </div>
        );
    }
}
